body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container{
  width: 100%;
  height: 50%;
}

.center{
  margin: 0 auto;
  text-align: center;
}

.bd-example {
  border: solid #eee;
  padding: 5rem;
  margin-top: .5rem;
  margin-right: 70rem;
  margin-bottom: 0;
  margin-left: 70rem;
  border-width: .2rem
}
