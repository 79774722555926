@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

html, body, #root {
    height:100%;
}

.buzzword-home{
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    align-items:stretch;
    height:100%;
}

body,h1,h2,h3,h4,h5,p{
    font-family:'DM Sans', sans-serif;
}

.icon-link{
    position:absolute;
    height:15px;
    width:15px;
    color:#2044f4;
    right:25px;
    bottom:18px;
}

.icon-link:hover{
    cursor: pointer;
    color:#3A7EF4;
    text-decoration:none;
}

.copy-confirmation{
    position:absolute;
    right:50px;
    bottom:1px;
    font-size:12px;
    color:#2044f4;
    -webkit-animation: fadeinout 4s linear forwards;
    animation: fadeinout 4s linear forwards;
}

  
/* Top Banner */
.banner-top{
    background-color:#2044F4;
}

.banner-top p{
    font-size:14px;
    color:white;
    font-weight:bold;
    text-align:center;
    margin:7px 0;
}

/* End Top Banner */

/* Logo */
.logo-container{
    margin-top:25px;
}

.logo-container img{
    display:block;
    margin:0 auto;
    max-width:160px;
}
/* End Logo */

.main-content{
    text-align:center;
    height:auto;
}

.main-content h1{
    font-size:24px;
    font-weight:bold;
    margin-top:20px;
}

.highlight{
    background: #C7F9FC;
    background: linear-gradient(360deg, #C7F9FC 30%, rgba(0,212,255,0) 30%);
}

.main-content .buzzphrase{
    border:4px solid #2044F4;
    border-radius:10px;
    padding:30px 20px;
    font-size:18px;
}

.last-line-row{
    background: rgb(199,249,252);
    background: linear-gradient(0deg, rgba(199,249,252,1) 50%, rgba(0,212,255,0) 50%);
}

/* Center Content */
.main-content .body-copy{
    display:block;
    margin:25px auto 30px;
}

.main-content .btn-outline-primary{
    display:block;
    margin:0 auto;
    border-color:#2044F4;
    color:#2044F4;
    border-radius: 30px;
    font-size:14px;
    font-weight:bold;
    padding:8px 35px;
}

.main-content .btn-outline-primary:hover{
    color:white;
    background-color:#2044F4;
    border-color:#2044F4;
}

/* Footer */

.footer{
    font-size:11px;
    margin:50px auto 10px;
}

.soft-animal-link{
    vertical-align: middle;
    text-align:center;
}

.soft-animal-link a{
    color: #2044F4;
    text-decoration: underline;
}

.coffee-button{
    max-width:120px;
    display:block;
    vertical-align: middle;
    float:none;
    margin:0 auto;
    text-align:center;
}
/* End Footer */

/**** Medium devices (tablets, 768px and up) ****/
@media (min-width: 768px) { 

    html, body, #root {
        height:100%;
    }

    .btn-outline-primary{
        margin-bottom:20px;
    }

    .main-content h1{
        font-size:40px;
        margin-top:0;
    }

    .headline{
        margin:0 auto;
    }

    .main-content .buzzphrase{
        border:4px solid #2044F4;
        border-radius:10px;
        padding:30px 20px;
        font-size:24px;
    }

    .coffee-button{
        float:right;
        margin:0;
    } 

    .soft-animal-link{
        text-align:left;
        margin-top:12px;
    }

    .logo-container img{
        display:block;
        margin:0;
        max-width:160px;
    }

 }
